//// Custom Theming for Angular Material
//// For more information: https://material.angular.io/guide/theming
//// Plus imports for other components in your app.
//
//// Include the common styles for Angular Material. We include this here so that you only
//// have to load a single css file for Angular Material in your app.
//// Be sure that you only ever include this mixin once!
//@use '~@angular/material' as mat;
//@include mat.core();
//
//// import our custom theme
@import "./assets/styles/ft-theme.scss";
//
//// import custom component themes
@import "./assets/styles/custom-component-themes.scss";
//
@import "./assets/styles/_variables.scss";
//
//@include mat.all-component-themes($theming-material-components-theme);
//@include custom-components-theme($theming-material-components-theme);
//
//// Include the dark color styles inside of a block with a CSS class. You can make this
//// CSS class whatever you want. In this example, any component inside of an element with
//// `.dark-theme` will be affected by this alternate dark theme instead of the default theme.
//
//.dark-theme {
//  @include mat.all-component-colors($dark-theme);
//}
//
//// Override typography for all Angular Material, including mat-base-typography and all components.
//@include mat.all-component-typographies($Montserrat-custom-typography);
//
//
///* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; padding: 0; font-family: Montserrat, "SansSerif", sans-serif; }
